import React from "react";
// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// @material-ui icons
import Help from "@material-ui/icons/Help";

import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import featuresStyle from "assets/jss/material-kit-pro-react/views/pricingSections/featuresStyle.js";
import { Helmet } from "react-helmet";
import Layout from "views/Layout/Layout.js";
import city from "assets/img/svg/questions.svg";
import { Link } from "gatsby";
import Button from "components/CustomButtons/Button.js";
import image1 from "assets/img/svg/1.svg";
import image2 from "assets/img/svg/2.svg";
import image3 from "assets/img/svg/3.svg";

const useStyles = makeStyles(featuresStyle);

export default function SectionFaqs() {
  const classes = useStyles();
  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'Website',
      'name': 'BUSLOC FAQ',
      'description': 'BUSLOC Faq',
      'url': 'www.busloc.io/faq',
      'keywords': 'BUSLOC, BUSLOC FAQ',
      'og:description': 'BUSLOC frequently asked questions',
      'og:title': 'BUSLOC - FAQ'

    },
  ];
  return (
    <Layout>
      <Helmet>
        <title>BUSLOC - FAQ</title>
        <meta name="description" content="BUSLOC school bus tracker FAQ" />
        <meta name="keywords" content="BUSLOC, BUSLOC FAQ" />
        {/* JSON-Ld tags*/}
        <script type="application/ld+json">
          {JSON.stringify(schemaOrgJSONLD)}
        </script>
      </Helmet>
      <br />
      <br />
      <div className={classes.featuresSection} style={{ backgroundImage: `url(${city})` }}>

        <GridContainer>
          <GridItem xs={12} sm={12} md={2} lg={2}></GridItem>
          <GridItem xs={12} sm={12} md={8} lg={8}>
            <Card raised style={{ padding: '10px' }}>
              <div className={classes.textCenter}>
                <h3 className={classes.title} style={{ fontSize: '30px' }}>Frequently Asked Questions</h3>
              </div>
              {/* <div style={{ textAlign: 'center' }}>
                <Button round size="sm" color="rose" component={Link} to="/faq/#general" style={{ fontWeight: 700 }} href="https://cp.busloc.io/auth/signup">
                  General
                       </Button>
                <Button size="sm" round color="warning" component={Link} to="/faq/#parent" style={{ fontWeight: 700 }} href="https://cp.busloc.io/auth/signup">
                  Parents/Students
                       </Button>
                <Button round size="sm" color="info" component={Link} to="/faq/#ta" style={{ fontWeight: 700 }} href="https://cp.busloc.io/auth/signup">
                  Transportation Staff
                       </Button>
              </div> */}
              <br />
              <Card style={{ backgroundColor: "#f2f2f2" }}>


                <a name="general">

                  <h2 className={classes.title} style={{ color: "#e91e63", paddingLeft: "15px" }}>General  </h2>
                </a>


                <CardBody>
                  <p className={classes.titlep} style={{ fontSize: '18px' }}>What is BUSLOC</p>
                  <p className={classes.descriptionp} style={{ fontWeight: 400 }}>BUSLOC is a free mobile notifications and GPS based location service for Parents and Students. Its primary aim is to serve schools and daycares.</p>

                  <p className={classes.titlep}>Is BUSLOC really Free</p>
                  <p className={classes.descriptionp}>Yes. It is absolutely free.</p>
{/* 
                  <p className={classes.titlep}>What is RouteKey®</p>
                  <p className={classes.descriptionp}>RouteKey® is a 6 character alphanumeric code specific for each route. It is auto generated when the Transportation Staff creates a route in the BUSLOC system. It allows Parents and Students on the route to subscribe to SMS messaging.
     The concept is proprietary to BUSLOC and simplifies the subscription mechanism.</p> */}
                </CardBody>

              </Card>
              <Card>


                <a id="parent">
                  <h2 className={classes.title} style={{ color: "#e91e63", paddingLeft: "15px" }}>Parents/Students  </h2>
                </a>

                <CardBody>
              {/*     <p className={classes.titlep}>What is a RouteKey®</p>
                  <p className={classes.descriptionp}>RouteKey® is a 6 character alphanumeric code specific for each route. It is auto generated when the Transportation Staff creates a route in the BUSLOC system. It allows Parents and Students on the route to subscribe to SMS messaging.
     The concept is proprietary to BUSLOC and simplifies the subscription mechanism.</p> */}

                  <p className={classes.titlep}>How to get schoolbus related information</p>
                  <p className={classes.descriptionp}>Ask your school Transportation Department to implement BUSLOC, and after BUSLOC is implemented school staff can send notifications based school bus related info to Parent.</p>

                 {/*  <p className={classes.titlep}>What to do with a RouteKey®</p>
                  <p className={classes.descriptionp}>Parents need the RouteKey® to subscribe to the SMS text messages and mobile notifications sent by the transportation administrator, scheduler or driver. If you don't have one,
     please request the appropriate transportation staff or the bus driver</p>

                  <p className={classes.titlep}>How to register with BUSLOC</p>
                  <p className={classes.descriptionp}>It is easy. Send a specifically designed SMS to BUSLOC (844-533-7085). The text should be in the format “addroutekey 123SW45” (without quotes).
                  The Schools Transportation staff has to make the 6-digit key available to you. By sending the SMS in the above format, the parent gets registered to BUSLOC with the mobile number and subscribed to the route for which the school created the above RouteKey®. Alternatively, Parents/Students can register Go to the BUSLOC website,
     locate the "SIGN UP" button on the top right corner and register with your mobile number.</p>

                  <p className={classes.titlep}>RouteKey® subscriptions</p>
                  <p className={classes.descriptionp}>Parents or Students can subscribe to SMS in one of the two ways. <br />(1) By sending a simple SMS text from the mobile to the BUSLOC number and text (see SMS based subscriptions below).
     <br />(2) By logging to your profile on BUSLOC and follow the instruction as in the web-based subscrptions below.</p>

                  <div><Button round color="rose" style={{ fontWeight: 700 }} component={Link} to="/parentssmsregistration">More details</Button></div>
                  <p className={classes.titlep}>Subscribe to messages through SMS</p>
                  <p className={classes.descriptionp}>Send a one line SMS to the BUSLOC number provided by the Transportation Staff.
     Sample SMS text to send: addroutekey 123SW45 </p>

                  <p className={classes.titlep}>Subscribe to messages through the web</p>
                  <p className={classes.descriptionp}>Signin to your BUSLOC account and complete your profile by adding your mobile number.
     Check the subscribe to SMS option</p>

                  <p className={classes.titlep}>How to turn off text messages alert</p>
                  <p className={classes.descriptionp}>Parents can turn off SMS notifications by removing the route from their account.
     <br />Parents can send a simpe SMS text the BUSLOC number provided by the Transporation Staff (sample SMS text: removeroutekey 123SW45).
     <br />Aternatively, the Parents can signin to their BUSLOC account and check unsubscribe</p> */}
{/* 
     <p className={classes.titlep}>How to turn off mobile notifications</p>
                  <p className={classes.descriptionp}>Parents can turn off mobile notifications by removing the route from their account.
     <br />Parents can send a simpe SMS text the BUSLOC number provided by the Transporation Staff (sample SMS text: removeroutekey 123SW45).
     <br />Aternatively, the Parents can signin to their BUSLOC account and check unsubscribe</p>

                  <p className={classes.titlep}>How to check the school routes registered by a Parent</p>
                  <p className={classes.descriptionp}>When Parents signin to their BUSLOC accout, their subscribed routes are displyed</p>

                  <p className={classes.titlep}>How to remove a school route from my account - Parent</p>
                  <p className={classes.descriptionp}>Parent can remove route from manage route section</p> */}
                </CardBody>
              </Card>
            {/*  <Card>


                <a id="ta">
                  <h2 className={classes.title} style={{ color: "#e91e63", paddingLeft: "15px" }}>Transportation Staff  </h2>
                </a>

                <CardBody>



                  <p className={classes.titlep} style={{ fontSize: '18px' }}>What is BUSLOC</p>
                  <p className={classes.descriptionp} style={{ fontWeight: 400 }}>BUSLOC is a free SMS text messaging and GPS based location service for Parents and Students. Its primary aim is to serve schools and daycares.</p>

                  <p className={classes.titlep}>Is BUSLOC really Free</p>
                  <p className={classes.descriptionp}>Yes. It is absolutely free.</p>

                  <p className={classes.titlep}>How to start with BUSLOC</p>
                  <h5 className={classes.descriptionp} style={{ fontWeight: 500 }}>Through SMS : </h5>
                  <p className={classes.descriptionp}>It can be done in 3 simple steps.</p>
                  <GridContainer>
                    <GridItem xs={3} sm={3} md={2}>
                      <p className={classes.title} style={{ color: "#e91e63", marginTop: "2px", marginBottom: "2px", minHeight: "10px" }}>Step : 1</p>
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                      <p className={classes.descriptionp}>
                        Send a specifically designed SMS to BUSLOC (844-533-7085). The text should be in the format “ar R1AM” (without quotes; replace R1AM with your route name/identifier). We will immediately message you the RouteKey®, specific to your route.
                       The above step registers you in BUSLOC as the owner of that route with your mobile number.</p>

                    </GridItem>
                  </GridContainer>

                  <GridContainer>
                    <GridItem xs={3} sm={3} md={2}>
                      <p className={classes.title} style={{ color: "#e91e63", marginTop: "2px", marginBottom: "2px", minHeight: "10px" }}>Step : 2</p>
                    </GridItem>
                    <GridItem xs={11} sm={11} md={11}>

                      <p className={classes.descriptionp}>
                        Distribute the above RouteKey to Parents/Students to start receiving SMS text messages and mobile notifications relating to the route.
                        Please see further
   <Button simple color="info" style={{ fontSize: "16px", fontWeight: 800, paddingLeft: "0px", paddingRight: "0px ",paddingTop: "0px", paddingBottom: "0px", textDecoration: 'underline' }} component={Link} to="/parentssmsregistration">instructions</Button>
                        for Parents/Students to register at BUSLOC and subscribe to the route.</p>

                    </GridItem>
                  </GridContainer>
                  <GridContainer>
                    <GridItem xs={3} sm={3} md={2}>
                      <p className={classes.title} style={{ color: "#e91e63", marginTop: "2px", marginBottom: "2px", minHeight: "10px" }}>Step : 3</p></GridItem>
                    <GridItem xs={11} sm={11} md={11}>
                      <p className={classes.descriptionp}>Start sending SMS from your mobile to the subscribed Parents on the route. To send SMS to parents from your mobile, prepend your message with the letters “sm” (again no quotes) followed by space.
</p>
                    </GridItem>
                  </GridContainer>
                  <div><Button round color="rose" style={{ fontWeight: 700 }} component={Link} to="/transportationstaffsmsregistration">More details</Button></div>

                  <h5 className={classes.descriptionp} style={{ fontWeight: 500 }}>Through Website : </h5>
                  <ul className={classes.descriptionp}>
                    <li>Alternatively, you can register on the BUSLOC website - locate the "SIGN UP" button on the top right corner and register with your mobile number.</li>
                    <li>After "Sign In", chose the "Transportation Staff" option to create a very brief profile. </li>
                    <li>You are then ready to add schools, routes, and route stops. Once you register, you can send SMS from the web to the parents subscribed on that route. </li>
                    <li>Please contact support@busloc.io if you face any difficulty. Note that if you have more than a handful of routes, you can email us the information in a spreadsheet and we will upload the data into BUSLOC for you.</li>
                  </ul>

                   <p className={classes.titlep}>What is BUSLOC control panel</p>
                    <p className={classes.descriptionp}>This is the default screen you will see when you login as Transportation Staff (after you initially register with BUSLOC as a user). This is where you add schools, routes and route stops.
     You also send SMS messages from here. You can also access the Control Panel routinely on your mobile</p>

                    <p className={classes.titlep}>What is a RouteKey®</p>
                    <p className={classes.descriptionp}>RouteKey® is a 6 character alphanumeric code specific for each route. It is auto generated when the Transportation Staff creates a route in the BUSLOC system. It allows Parents and Students on the route to subscribe to SMS messaging.
     The concept is proprietary to BUSLOC and simplifies the subscription mechanism.</p>

                    <p className={classes.titlep}>How to create a RouteKey®</p>
                    <p className={classes.descriptionp}>RouteKey® is auto generated when you create a route in BUSLOC
      either through SMS or the control panel on the web.</p>

                    <p className={classes.titlep}>How to share RouteKey® with Parents</p>
                    <p className={classes.descriptionp}>There is a "share RouteKey®" button in the routes page
                    in the control panel right next to the RouteKey®. Clicking on it pops up a box with a template message that you can copy and paste to send an email to the Parents.
     The email template also provides information to Parents on how to subscribe to SMS service on the BUSLOC platform</p>

                    <p className={classes.titlep}>How to send messages to routes</p>
                    <p className={classes.descriptionp}>As transportation authority or staff (scheduler, driver, etc) you can access the control panel on your desktop or mobile and a "Send SMS" button should be visible right upfront.
     You can type your message either to specific route(s) or broadcast a message to all routes</p>

                    <p className={classes.titlep}>How many messages can we send per route</p>
                    <p className={classes.descriptionp}>
                      A transport authority can send unlimited number of messages,
     however they are time limited to avoid abuse.</p>

                    <p className={classes.titlep}>
                      How to increase the account limit for schools, routes, route stops</p>
                    <p className={classes.descriptionp}>Please consult support@busloc.io</p>
</CardBody>
</Card> */} 
     </Card>
          </GridItem>
      </GridContainer>

            {/*    <InfoArea
            title="Is BUSLOC free?"
            description="Yes. Both SMS (text messaging) and live tracking are provided free of charge to all Transportation Staff, Drivers, Parents, and Students."
            icon={CardMembership}
            iconColor="info"
          />
      
      
          <InfoArea
            title="Is it free forever?"
            description="Yes. The SMS (text messaging) and location services are free forever. In future we may charge of additional services. "
            icon={CardGiftCard}
            iconColor="success"
          />
     
     
          <InfoArea
            title="Are there any restrictions?"
            description="No.  BUSLOC supports unlimited routes and unlimited users."
            icon={QuestionAnswer}
            iconColor="rose"
          /> */}
     
         
    </div>
    </Layout>
        );
      }
